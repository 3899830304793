import React from "react"

export default function CareerHeader() {
  return (
    <div>
      <header className="page-header">
        <div className="container">
          <h1>CAREERS</h1>
          
          <ul>
            <li>
              <a href="/">HOME</a>
            </li>
            <li>CAREERS</li>
          </ul>
        </div>
      </header>
    </div>
  )
}
