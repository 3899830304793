import React from "react"

export default function CareerBody() {
  return (
    <div>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-left">
                <h6></h6>
                <h2>OPEN VACANCIES</h2>
              </div>
            </div>

            <div className="row info-line">
              <div className="col-lg-8 ">
                <div className="accordion" id="accordion" role="tablist">
                  <div className="card">
                    <div className="card-header" role="tab" id="headingOne">
                      {" "}
                      <a
                        data-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {" "}
                        Site Engineer
                        <i className="lni lni-arrow-right"></i>
                      </a>{" "}
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        {" "}
                        We are looking for a dynamic person with an engineering
                        background with a minimum of 5 years of experience in a
                        similar capacity. The person should possess leadership
                        skills and the ability to work independently with a
                        result-oriented mindset. He should be willing to work in
                        Bangladesh or Maldives.{" "}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo">
                      {" "}
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {" "}
                        Quantity Surveyor
                        <i className="lni lni-arrow-right"></i>
                      </a>{" "}
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      role="tabpanel"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        {" "}
                        He or She will have to support the tendering team and also visit the sites if needed.
Minimum experience of 5 years in the related field with the ability to handle both Civil & MEP work.{" "}
                      </div>
                    </div>
                  </div>

                
                </div>
              </div>

              <div className="col-lg-4">
                <div className="info-box-dark">
                  <h6></h6>
                  <p>
                    We primarily seek out people with{" "}
                    <strong>capability</strong> and <strong>integrity</strong>
                    ,The absence of one will make the other totally useless and
                    ineffective. VIRTUE is an{" "}
                    <u>Equal Opportunity Employer (EOE)</u>. We do not
                    discriminate against any employee or job applicant because
                    of race, color, religion, national origin, sex, physical or
                    mental disability, or age.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
